<template>
  <router-view v-if="showPage"></router-view>
</template>

<script>
export default {
  name: 'AccountBalance',
  data () {
    return {
      type: -1,
      showPage: false,
    }
  },
  created () {
    this.type = this.$store.state.member.memberCenter.status
    if (typeof this.type === 'undefined') {
      this.getPcMemberMemberCenter()
    }
		this.isType()
  },
  methods: {
    getPcMemberMemberCenter () {
      // 个人中心 会员中心数据
      this.$store
        .dispatch('PcMemberMemberCenter', {})
        .then((res) => {
          if (res.returnCode === '1') {
						this.type = this.$store.state.member.memberCenter.status
						this.isType()
          }
        })
        .catch(() => {})
    },
    isType () {
      if (this.type === 7) {
        // 已开户
        this.$router.push('/user/account/balance').then(() => {
          this.showPage = true
        })
      } else if (this.type === 4 || this.type === 5 || this.type === 6) {
        // 待开户 | 开户失败
        this.$router.push('/user/account/balance/open-account').then(() => {
          this.showPage = true
        })
      }
    },
  },
}
</script>
